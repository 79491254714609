import React from 'react';

interface PhotoIconProps {
  className?: string;
}

export default function PhotoIcon({ className = '' }: PhotoIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="#289ED7"
      viewBox="0 0 79 78"
    >
      <path d="M39.4069 0.0769043C31.7583 0.0767065 24.2813 2.34478 17.9216 6.59399C11.5619 10.8432 6.60505 16.8829 3.67791 23.9492C0.750763 31.0156 -0.0151961 38.7913 1.47686 46.293C2.96891 53.7947 6.65196 60.6855 12.0603 66.094C17.4686 71.5025 24.3594 75.1857 31.861 76.678C39.3627 78.1702 47.1384 77.4043 54.2048 74.4773C61.2713 71.5503 67.311 66.5937 71.5604 60.2341C75.8098 53.8745 78.0779 46.3977 78.0779 38.749C78.0662 28.4963 73.9883 18.6668 66.7386 11.417C59.489 4.16714 49.6596 0.0888145 39.4069 0.0769043ZM71.6679 21.843L53.0989 40.334L52.6269 5.32104C60.2689 8.64404 67.8079 14.508 71.6679 21.843ZM39.4069 2.32007C43.0886 2.31762 46.7492 2.87385 50.2639 3.96997L50.2179 30.3159L25.0769 5.80005C29.2269 4.15305 34.6749 2.32007 39.4069 2.32007ZM49.8899 34.407V43.0911L43.7489 49.2319H35.0639L28.9229 43.0911V34.407L35.0639 28.2661H43.7489L49.8899 34.407ZM22.4999 6.48901L41.1609 25.104L5.97887 25.5261C9.29887 17.8861 15.1659 10.346 22.4989 6.48901H22.4999ZM2.97692 38.75C2.97351 35.068 3.53124 31.407 4.63091 27.8931L30.9899 27.887L6.45586 53.0791C4.80986 48.9261 2.97692 43.479 2.97692 38.75ZM7.14586 55.657L25.7279 37.0959L26.0489 72.175C18.4089 68.856 11.0059 62.991 7.14586 55.657ZM39.4069 75.1799C35.7252 75.1832 32.0645 74.6257 28.5509 73.5261L28.6119 47.2661L53.9789 72.626C49.3073 74.3192 44.3759 75.1835 39.4069 75.1799ZM56.3149 71.011L37.1149 52.0229L72.8349 51.9719C69.5129 59.6119 63.6489 67.151 56.3149 71.011ZM47.3839 49.4929L72.4909 24.554C74.1379 28.704 75.8369 34.0179 75.8369 38.7539C75.8399 42.4355 75.2825 46.0963 74.1839 49.6101L47.3839 49.4929Z" />
    </svg>
  );
}
