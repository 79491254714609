import React from 'react';

interface InstagramIconProps {
  className?: string;
}

export default function InstagramIcon({ className = '' }: InstagramIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M0.329102 17.3481V7.2002C0.343102 7.0592 0.356094 6.91783 0.371094 6.77783C0.444804 6.07329 0.636133 5.38639 0.937103 4.74512C1.57282 3.36155 2.66827 2.24109 4.03709 1.57422C4.71555 1.2355 5.44973 1.022 6.2041 0.944824L6.6411 0.902832H16.7611C16.7861 0.908495 16.8115 0.912711 16.8371 0.915039C17.289 0.937568 17.7372 1.00824 18.1741 1.12598C19.5614 1.50138 20.7888 2.31808 21.671 3.45264C22.5531 4.58719 23.0421 5.97804 23.0641 7.41504C23.0871 10.641 23.0721 13.8673 23.0701 17.0933C23.0678 17.736 22.9694 18.3742 22.7781 18.9878C22.3598 20.3331 21.5234 21.5103 20.3904 22.3477C19.2574 23.185 17.8869 23.6391 16.4781 23.644C13.3161 23.644 10.1538 23.644 6.9911 23.644C6.7811 23.644 6.57009 23.6342 6.36009 23.6182C5.61538 23.5595 4.88706 23.3689 4.20911 23.0552C2.8987 22.4623 1.81836 21.4565 1.1331 20.1919C0.718987 19.4418 0.459847 18.6164 0.371094 17.7642C0.355094 17.6202 0.343102 17.4861 0.329102 17.3481ZM11.7001 2.64795C10.1441 2.64795 8.58776 2.64795 7.0311 2.64795C6.8311 2.64795 6.63111 2.6558 6.43111 2.6748C5.75383 2.72938 5.09804 2.93872 4.5141 3.28613C3.75945 3.70383 3.13358 4.32022 2.70433 5.06836C2.27508 5.8165 2.05885 6.66749 2.0791 7.52979C2.0611 10.6898 2.0741 13.8503 2.0741 17.0103C2.07332 17.3702 2.11086 17.729 2.1861 18.0811C2.41692 19.1547 3.00751 20.1174 3.86006 20.8096C4.71261 21.5017 5.77598 21.8819 6.8741 21.8872C10.0581 21.8992 13.2411 21.8872 16.4251 21.8872C16.7115 21.8872 16.9974 21.8638 17.2801 21.8179C18.1656 21.6818 18.9925 21.2913 19.6601 20.6938C20.1905 20.2382 20.614 19.6712 20.9002 19.0332C21.1864 18.3952 21.3284 17.7021 21.3161 17.0029C21.3214 13.8463 21.3214 10.6892 21.3161 7.53125C21.3168 7.33449 21.3064 7.13749 21.2851 6.94189C21.1518 5.76845 20.5937 4.68433 19.7161 3.89404C18.8385 3.10376 17.7021 2.66189 16.5211 2.65186C14.9171 2.63786 13.3081 2.65283 11.6991 2.65283L11.7001 2.64795Z" />
      <path d="M11.7072 6.15088C12.9179 6.15246 14.1011 6.51316 15.107 7.18701C16.1129 7.86086 16.8964 8.81792 17.3586 9.93701C17.8207 11.0561 17.9407 12.2868 17.7034 13.4741C17.4661 14.6614 16.8821 15.7515 16.0253 16.6069C15.1684 17.4624 14.0772 18.0449 12.8895 18.2803C11.7019 18.5157 10.4711 18.3937 9.35277 17.9297C8.23444 17.4657 7.27878 16.6804 6.60657 15.6733C5.93436 14.6663 5.57578 13.4827 5.57617 12.272C5.58067 10.6482 6.22837 9.09254 7.37747 7.94531C8.52657 6.79809 10.0834 6.15272 11.7072 6.15088ZM11.7072 7.8999C10.8419 7.89931 9.9959 8.15544 9.27618 8.63574C8.55647 9.11605 7.99538 9.79889 7.66389 10.5981C7.33241 11.3974 7.24541 12.2773 7.41393 13.126C7.58244 13.9747 7.99889 14.7542 8.61058 15.3662C9.22228 15.9782 10.0017 16.3951 10.8504 16.564C11.699 16.7329 12.5786 16.6461 13.378 16.3149C14.1774 15.9838 14.8607 15.4231 15.3413 14.7036C15.8219 13.9841 16.0784 13.1382 16.0782 12.2729C16.0796 11.6978 15.9673 11.1283 15.7477 10.5967C15.5281 10.0651 15.2055 9.58173 14.7985 9.17529C14.3915 8.76885 13.9081 8.44692 13.3762 8.22803C12.8443 8.00913 12.2744 7.89766 11.6992 7.8999H11.7072Z" />
      <path d="M18.2638 4.40186C18.5239 4.40267 18.7779 4.48065 18.9935 4.62598C19.2092 4.77132 19.3769 4.97752 19.4752 5.21827C19.5736 5.45901 19.5983 5.72374 19.5461 5.97852C19.4939 6.2333 19.3672 6.46672 19.1822 6.64942C18.9971 6.83212 18.7619 6.95554 18.5065 7.0044C18.251 7.05326 17.9868 7.02532 17.7474 6.92383C17.5079 6.82235 17.304 6.65211 17.1615 6.43458C17.019 6.21704 16.9443 5.96221 16.9468 5.70215C16.9503 5.35549 17.0907 5.02431 17.3374 4.78077C17.5841 4.53722 17.9172 4.40079 18.2638 4.40186Z" />
    </svg>
  );
}
