export default function LeftRoundIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      viewBox="0 0 24 23"
    >
      <path
        d="M0.937013 11.52C0.936024 13.7534 1.59745 15.9368 2.83763 17.7942C4.07781 19.6516 5.84102 21.0995 7.90421 21.9546C9.96739 22.8097 12.2378 23.0339 14.4283 22.5984C16.6189 22.1629 18.631 21.0875 20.2102 19.5083C21.7895 17.9291 22.8649 15.9168 23.3003 13.7263C23.7358 11.5358 23.5118 9.26533 22.6566 7.20215C21.8015 5.13897 20.3537 3.37592 18.4963 2.13574C16.6389 0.895562 14.4554 0.23412 12.222 0.235109C9.22962 0.236963 6.3603 1.42654 4.24436 3.54248C2.12841 5.65843 0.938867 8.52762 0.937013 11.52ZM7.07401 11.5291L13.868 4.61597L14.968 5.68799L9.22801 11.52L14.958 17.344L13.868 18.4251L7.07401 11.5291Z"
        fill="currentColor"
      />
    </svg>
  );
}
