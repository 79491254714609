/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import axios from 'axios';
import NProgress from 'nprogress';
import { validatePhone, validateString } from 'avilatek-utils';
import { useRequestCategories } from '../../hooks';
import { EMAIL_TEMPLATES_NAMES } from '../../config';
import CloseIcon from '../icons/CloseIcon';
import Modal from '../modal/Modal';
import WarningModal from '../modal/WarningModal';

interface InfoModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const employeeOptions = [
  'Ventas',
  'Operaciones',
  'Producción',
  'Mercadeo',
  'Finanzas',
  'Administración',
  'Desarrollo',
];

export default function InfoModal({ isOpen, setOpen }: InfoModalProps) {
  const { data, loading, error } = useRequestCategories();
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLasName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [phoneOptional, setPhoneOptional] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [type, setType] = React.useState('');
  const [option, setOption] = React.useState('none');
  const [comments, setComments] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [title, setTitle] = React.useState(null);

  React.useEffect(() => {
    setFirstName('');
    setLasName('');
    setPhone('');
    setPhoneOptional('');
    setEmail('');
    setCompanyName('');
    setType('');
    setOption('none');
    setComments('');
  }, [isOpen]);

  async function sendEmails() {
    const resData = await axios.post('/api/send-email', {
      template: EMAIL_TEMPLATES_NAMES.WORK_WITH_US_DATA,
      from: 'Bloop',
      firstName,
      lastName,
      email,
      phone,
      message: comments,
      phoneOptional,
      company: companyName,
      type,
      category: option,
    });
    const resClient = await axios.post('/api/send-email', {
      template: EMAIL_TEMPLATES_NAMES.WORK_WITH_US_CLIENT,
      from: 'Bloop',
      email,
      name: firstName,
    });
    await Promise.allSettled([resData, resClient]);
  }

  const onClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      if (!validateString(firstName)) {
        setMessage('El campo NOMBRE es inválido');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      if (!validateString(lastName)) {
        setMessage('El campo APELLIDO es inválido');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      if (!validatePhone(phone)) {
        setMessage('El campo TELÉFONO es inválido');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      if (!validateString(email)) {
        setMessage('El campo CORREO es inválido');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      if (!validateString(type)) {
        setMessage(
          'Debes seleccionar si deseas un puesto de trabajo o eres proveedor'
        );
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      if (!validateString(option)) {
        setMessage('Debes seleccionar una opción');
        setTitle(null);
        setOpenWarning(true);
        return;
      }
      setDisabled(true);
      NProgress.start();
      await sendEmails();
      setDisabled(false);
      NProgress.done();
      setOpen(false);
    } catch (err) {
      setDisabled(false);
      NProgress.done();
      console.log(err);
    }
  };

  return (
    <Modal isOpen={isOpen} className="w-full sm:w-auto">
      {loading ? null : (
        <>
          <div className="sm:w-full lg:w-100 w-11/12 h-auto flex flex-col bg-primary-400 rounded-md justify-start items-center text-white m-auto">
            <div className="h-8 w-full flex justify-end p-2.5">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </button>
            </div>
            <h2 className="text-4xl mb-8">Trabaja con Nosotros</h2>
            <form
              method="POST"
              className="w-11/12 flex flex-col text-white items-center"
            >
              <div className="w-full flex flex-row space-x-4">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Nombre"
                  value={firstName}
                  className="w-1/2 bg-transparent border-0 appearance-none text-sm focus:shadow-none focus:border-0 focus:ring-transparent border-secondary-500 focus:border-secondary-500 border-b-2 py-1 mb-4"
                  onChange={(e) => {
                    e.preventDefault();
                    setFirstName(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Apellido"
                  value={lastName}
                  className="w-1/2 bg-transparent border-0 appearance-none text-sm focus:shadow-none focus:border-0 focus:ring-transparent border-secondary-500 focus:border-secondary-500 border-b-2 py-1 mb-4"
                  onChange={(e) => {
                    e.preventDefault();
                    setLasName(e.target.value);
                  }}
                />
              </div>
              <div className="w-full flex flex-row space-x-4">
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Teléfono"
                  value={phone}
                  className="w-1/2 bg-transparent border-0 appearance-none text-sm focus:shadow-none focus:border-0 focus:ring-transparent border-secondary-500 focus:border-secondary-500 border-b-2 py-1 mb-4"
                  onChange={(e) => {
                    e.preventDefault();
                    setPhone(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name="optional"
                  id="optional"
                  placeholder="(Opcional)"
                  value={phoneOptional}
                  className="w-1/2 bg-transparent border-0 appearance-none text-sm focus:shadow-none focus:border-0 focus:ring-transparent border-secondary-500 focus:border-secondary-500 border-b-2 py-1 mb-4"
                  onChange={(e) => {
                    e.preventDefault();
                    setPhoneOptional(e.target.value);
                  }}
                />
              </div>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Correo"
                value={email}
                className="w-full bg-transparent border-0 appearance-none text-sm focus:shadow-none focus:border-0 focus:ring-transparent border-secondary-500 focus:border-secondary-500 border-b-2 py-1 mb-4"
                onChange={(e) => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
              />
              <input
                type="text"
                name="companyName"
                id="companyName"
                value={companyName}
                placeholder="Nombre de la Empresa (Opcional)"
                className="w-full bg-transparent border-0 appearance-none text-sm focus:shadow-none focus:border-0 focus:ring-transparent border-secondary-500 focus:border-secondary-500 border-b-2 py-1 mb-4"
                onChange={(e) => {
                  e.preventDefault();
                  setCompanyName(e.target.value);
                }}
              />
              <h2 className="text-left w-full mt-1">
                ¿Eres proveedor o solicitas un puesto de trabajo?
              </h2>
              <div className="flex flex-row w-full my-2">
                <div className="flex flex-col w-1/3">
                  <div className="flex items-center">
                    <input
                      id="push_everything"
                      name="push_notifications"
                      type="radio"
                      className="h-4 w-4 text-secondary-500 focus:shadow-none focus:appearance-none  focus:ring-transparent focus:border-none appearance-none shadow-none"
                      onClick={(e) => {
                        setType('Proveedor');
                      }}
                    />
                    <label htmlFor="supplier" className="ml-3 block bebas">
                      Proveedor
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="push_everything"
                      name="push_notifications"
                      type="radio"
                      className="h-4 w-4 text-secondary-500 focus:shadow-none focus:appearance-none  focus:ring-transparent focus:border-none appearance-none shadow-none"
                      onClick={(e) => {
                        setType('Trabajo');
                      }}
                    />
                    <label htmlFor="work" className="ml-3 block bebas">
                      Trabajo
                    </label>
                  </div>
                </div>
                <div className="w-2/3">
                  <select
                    name="supplier"
                    id="supplier"
                    className={`focus:shadow-none focus:ring-transparent focus:border-none w-full py-0 border-none bg-transparent appearance-none text-sm text-white ${
                      type === 'Proveedor' ? 'flex' : 'hidden'
                    } ${option === 'none' ? 'text-gray-500' : 'text-white'}`}
                    value={option}
                    onChange={(e) => {
                      e.preventDefault();
                      setOption(e.target.value);
                    }}
                  >
                    <option value="none" disabled>
                      Seleccione un opción
                    </option>
                    {data?.map((_option) => (
                      <option value={_option?.name} key={_option?.id ?? ''}>
                        {_option?.name ?? ''}
                      </option>
                    ))}
                  </select>
                  <select
                    name="supplier"
                    id="supplier"
                    className={`focus:shadow-none focus:ring-transparent focus:border-none w-full py-0 border-none bg-transparent appearance-none text-sm text-white ${
                      type === 'Trabajo' ? 'flex mt-6' : 'hidden'
                    } ${option === 'none' ? 'text-gray-500' : 'text-white'}`}
                    value={option}
                    onChange={(e) => {
                      e.preventDefault();
                      setOption(e.target.value);
                    }}
                  >
                    <option value="none" disabled>
                      Seleccione un opción
                    </option>
                    {employeeOptions.map((_option) => (
                      <option value={_option} key={_option}>
                        {_option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <h2 className="text-left w-full mt-3">Coméntanos (Opcional)</h2>
              <textarea
                className="w-full bg-transparent text-sm appearance-none h-20 resize-none border-secondary-500 border-2 scrolly overflow-x-hidden overflow-y-scroll focus:shadow-none focus:border-secondary-500 focus:ring-transparent my-1"
                name="opinion"
                id="opinion"
                value={comments}
                onChange={(e) => {
                  e.preventDefault();
                  setComments(e.target.value);
                }}
              />
              <input
                type="button"
                className="bg-secondary-500 text-primary-400 text-sm rounded-full bebas hover:bg-secondary-400 cursor-pointer px-8 py-1 my-5 focus:shadow-none focus:ring-transparent focus:b-none focus:outline-none"
                value="Enviar"
                disabled={disabled}
                onClick={onClick}
              />
            </form>
          </div>
        </>
      )}
      <WarningModal
        isOpen={openWarning}
        setOpen={setOpenWarning}
        message={message}
        title={title}
      />
    </Modal>
  );
}
