import React from 'react';

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        fill="#29ABE2"
        d="M8.451 9.812l.7-.695-3.72-3.75.417-.549 3.292-3.3-.7-.7-3.8 3.773-.486-.472L.868.832l-.7.7 2.114 2.124c.3.3.6.592.917.9l.756.74-.473.5L.153 9.108l.7.7 3.841-3.792.378.4.072.1 3.307 3.296z"
      />
    </svg>
  );
}

export default CloseIcon;
