import React from 'react';

interface MaskIconProps {
  className?: string;
}

export default function MaskIcon({ className = '' }: MaskIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="#289ED7"
      viewBox="0 0 87 41"
    >
      <path d="M27.8529 28.2609C30.1523 28.5453 32.4726 28.6226 34.7859 28.4919C35.9116 28.412 37.0428 28.4659 38.1559 28.652L39.2459 28.7799L39.3529 27.6869C39.3619 27.6099 40.0039 19.966 32.9369 16.319C25.9119 12.702 17.8229 18.078 17.4819 18.309L16.9009 18.6989L17.0249 19.392C17.6193 21.847 18.9981 24.041 20.9521 25.6415C22.9062 27.2421 25.329 28.1617 27.8529 28.2609ZM34.6119 26.3429C32.4168 26.4638 30.2152 26.3869 28.0339 26.113C21.9339 25.599 19.9339 21.551 19.3339 19.703C21.1929 18.64 27.0839 15.717 31.9509 18.235C33.4711 19.0058 34.7593 20.1663 35.684 21.5981C36.6086 23.0298 37.1366 24.6813 37.2139 26.3839C36.3509 26.2826 35.4797 26.2688 34.6139 26.3429L34.6209 26.497L34.6119 26.3429Z" />
      <path d="M48.0551 28.78L49.1461 28.6521C50.2611 28.4655 51.3944 28.4119 52.5221 28.4929C54.8334 28.6252 57.1519 28.5476 59.4491 28.261C61.9729 28.1613 64.3954 27.2416 66.3494 25.6411C68.3033 24.0407 69.6823 21.8468 70.2771 19.3921L70.4011 18.7019L69.8201 18.3091C69.4791 18.0781 61.4051 12.6851 54.3641 16.3191C47.2981 19.9661 47.9401 27.61 47.9481 27.688L48.0551 28.78ZM50.0981 26.3801C50.1815 24.6802 50.7111 23.0321 51.6336 21.6018C52.5561 20.1715 53.8389 19.0092 55.3531 18.2319C60.2141 15.7239 66.1081 18.637 67.9691 19.7C67.3691 21.547 65.3691 25.6001 59.2691 26.1101C57.0806 26.387 54.8715 26.4636 52.6691 26.3391C52.3571 26.3151 52.0611 26.293 51.6751 26.293C51.2451 26.296 50.7431 26.3201 50.0991 26.3831L50.0981 26.3801Z" />
      <path d="M85.725 10.2561C85.393 9.88814 77.6769 1.39716 70.8959 0.84916C65.5279 0.42316 60.065 1.75615 56.45 2.64115C55.695 2.82515 54.9819 3.00017 54.3619 3.13017C47.0061 4.91952 39.328 4.91576 31.9739 3.11918C31.4399 3.00718 30.795 2.85115 29.939 2.64115C26.326 1.75915 20.8619 0.43316 15.4919 0.84916C8.70794 1.39816 0.986964 9.89514 0.662964 10.2561L0.208984 10.7561L5.50897 23.8731C10.5 33.8581 17.418 39.3521 26.654 40.6731C27.6476 40.8165 28.6501 40.8885 29.654 40.889C34.923 40.889 38.835 38.849 40.243 37.312C40.637 36.9303 41.103 36.6308 41.614 36.4309C42.1249 36.2311 42.6705 36.1348 43.2189 36.148H43.363H43.374L43.436 36.4351V36.1521C44.4516 36.183 45.418 36.5962 46.142 37.3091C47.719 39.0321 51.707 40.886 56.736 40.886C57.7368 40.8853 58.7363 40.8134 59.727 40.6709C68.965 39.3519 75.892 33.8359 80.908 23.7959L86.139 10.846L86.175 10.7561L85.725 10.2561ZM29.4319 4.73515C30.2539 4.93515 30.963 5.11002 31.459 5.20902C39.1375 7.12492 47.1684 7.13149 54.85 5.22807C55.413 5.11107 56.1089 4.94307 56.9689 4.72807C60.4519 3.87707 65.712 2.59116 70.723 2.99516C75.734 3.39916 81.8099 9.29517 83.6689 11.2132L78.9449 22.9131C74.2929 32.2131 67.908 37.324 59.425 38.535C53.257 39.4119 48.8819 37.1051 47.7319 35.8501C46.6304 34.7102 45.1274 34.0453 43.543 33.9971L43.527 33.981L43.074 33.9881C42.2509 33.9901 41.4364 34.1557 40.678 34.4754C39.9195 34.795 39.2322 35.2624 38.6559 35.8501C37.5069 37.1051 33.131 39.4109 26.963 38.535C18.48 37.324 12.1019 32.2351 7.47394 22.9851L2.71893 11.2151C4.57793 9.29313 10.662 3.39911 15.665 2.99711C20.668 2.59511 25.9319 3.88015 29.4319 4.73515Z" />
    </svg>
  );
}
