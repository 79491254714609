// import WatchImg from './img/WatchImg';

const items = [
  {
    title: ' ',
    text:
      'A través de herramientas digitales y una gran cartera de aliados estratégicos, le ofrecemos a nuestros clientes la oportunidad de conseguir rápidamente varias propuestas para que pueda comparar y elegir al proveedor adecuado que más se ajuste a sus necesidades y requerimientos.',
  },
  {
    title: ' ',
    text:
      'Con la ayuda de nuestros ejecutivos, los asesoramos durante todo el proceso, los guiamos durante la investigación y selección de proveedores y supervisamos cada detalle de la ejecución.',
  },
  {
    title: ' ',
    text:
      'Simplificar la planificación de eventos es nuestro propósito. Cuéntanos sobre tu próxima celebración y descubre lo fácil que es planificar un evento con Bloop',
  },
];

export default function AboutUs() {
  return (
    <section className="w-full min-h-screen text-white mt-6 md:mt-16 lg:mt-26 flex flex-col justify-center items-center sm:mt-10">
      <div
        className="w-full flex justify-center items-center sm:mb-5 anchor"
        id="about-us"
      >
        <h2 className="uppercase text-3xl md:text-4xl lg:text-5xl">
          ¿Qué es <span className="text-secondary-500">Bloop?</span>
        </h2>
      </div>
      <div className="w-full flex flex-col md:flex-row justify-center items-center space-x-1">
        <div className="p-8 w-full md:w-2/3 lg:w-1/2 max-w-lg md:max-w-none mx-auto lg:mx-0">
          <img
            src="/landing-page/tasks.png"
            alt="task platform"
            className="w-full"
          />
        </div>
        <div className="text-sm md:text-base w-full md:w-2/3 lg:w-1/2 max-w-lg md:max-w-none mx-auto lg:mx-0">
          {/* Section title */}
          {/* Content */}
          <p className="leading-snug text-justify">
            Bloop es una empresa que nace con la iniciativa de facilitar la
            difícil tarea de planificar eventos.
          </p>
          {items.map((item) => (
            <div key={item.text} className="mt-5">
              <p className="text-secondary-500 font-bold">{item.title}</p>
              <p className="leading-snug text-justify">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
