import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { AnimatePresence } from 'framer-motion';
import BottleIcon from '../../icons/BottleIcon';
import CateringIcon from '../../icons/CateringIcon';
import ChairIcon from '../../icons/ChairIcon';
import DesignIcon from '../../icons/DesignIcon';
import DessertIcon from '../../icons/DessertIcon';
import LeftRoundIcon from '../../icons/LeftRoundIcon';
import MaskIcon from '../../icons/MaskIcon';
import PhotoIcon from '../../icons/PhotoIcon';
import RightRoundIcon from '../../icons/RightRoundIcon';
import SecurityManIcon from '../../icons/SecurityManIcon';
import StageIcon from '../../icons/StageIcon';
import VideoIcon from '../../icons/VideoIcon';
import ServiceItem from './ServiceItem';

const firstRow = [
  {
    icon: <DesignIcon className="h-16 w-16 lg:h-20 lg:w-20" />,
    title: 'Diseño',
  },
  {
    icon: <StageIcon className="h-16 w-16 lg:h-20 lg:w-20" />,
    title: 'Stage',
  },
  {
    icon: <PhotoIcon className="h-16 w-16 lg:h-20 lg:w-20" />,
    title: 'Foto',
  },
  {
    icon: <VideoIcon className="h-16 w-16 lg:h-20 lg:w-20" />,
    title: 'Video',
  },
  {
    icon: <BottleIcon className="h-16 w-16 lg:h-20 lg:w-20" />,
    title: 'Licor',
  },
];

const secondRow = [
  {
    icon: <CateringIcon className="h-16 w-16 lg:h-20 lg:w-20" />,
    title: 'Catering',
  },
  {
    icon: <DessertIcon className="h-16 w-16 lg:h-20 lg:w-20" />,
    title: 'Postres',
  },
  {
    icon: <ChairIcon className="h-16 w-16 lg:h-20 lg:w-20" />,
    title: 'Inmobiliario',
  },
  {
    icon: <MaskIcon className="h-16 w-16 lg:h-20 lg:w-20" />,
    title: 'Cotillón',
  },
  {
    icon: <SecurityManIcon className="h-16 w-16 lg:h-20 lg:w-20" />,
    title: 'Seguridad',
  },
];

export default function Services() {
  const [positions, setPositions] = useState([]);
  const [first, setFirst] = useState([]);
  const [second, setSecond] = useState([]);

  useEffect(() => {
    firstRow.push(...firstRow);
    secondRow.push(...secondRow);

    setPositions(Array.from(Array(firstRow.length).keys()));
    setFirst(firstRow);
    setSecond(secondRow);
  }, []);

  const rotate = (change: number) => {
    const { length } = first;

    setFirst((prev) => {
      if (change === -1) {
        return prev.map((_, idx) => prev[(idx + change + length) % length]);
      }
      return prev.map((_, idx) => prev[(idx + change) % length]);
    });

    setSecond((prev) => {
      if (change === -1) {
        return prev.map((_, idx) => prev[(idx + change + length) % length]);
      }
      return prev.map((_, idx) => prev[(idx + change) % length]);
    });
  };

  return (
    <section className="min-h-screen w-full text-white mt-28 md:mt-28">
      {/* Section title */}
      <div id="services" className="anchor w-full text-center mt-20">
        <h2 className="uppercase text-3xl md:text-4xl lg:text-5xl">
          Servicios
        </h2>
      </div>

      {/* Content */}
      {/* Slider */}
      <div className="w-full h-108 mt-4 lg:mt-2 flex justify-center items-center text-secondary-500">
        {/* Left button */}
        <button
          type="button"
          className="block xl:hidden focus:ring-0 focus:outline-none hover:text-secondary-400"
          onClick={() => rotate(1)}
        >
          <LeftRoundIcon />
        </button>

        {/* Carousel content */}
        <div className="mx-10 md:ml-20 md:mr-24 overflow-hidden">
          <div className="w-30 sm:w-96 lg:w-108 xl:w-116 h-36 mx-auto my-10">
            <ul className="flex">
              <AnimatePresence>
                {positions.map((pos, idx) => (
                  <ServiceItem key={uuid()} pos={pos} idx={idx} row={first} />
                ))}
              </AnimatePresence>
            </ul>
          </div>
          <div className="w-30 sm:w-96 lg:w-108 xl:w-116 h-36 mx-auto my-10">
            <ul className="flex">
              <AnimatePresence>
                {positions.map((pos, idx) => (
                  <ServiceItem key={uuid()} pos={pos} idx={idx} row={second} />
                ))}
              </AnimatePresence>
            </ul>
          </div>
        </div>

        {/* Right button */}
        <button
          type="button"
          className="block xl:hidden focus:ring-0 focus:outline-none hover:text-secondary-400"
          onClick={() => rotate(-1)}
        >
          <RightRoundIcon />
        </button>
      </div>
    </section>
  );
}
