export default function RightRoundIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      viewBox="0 0 24 23"
    >
      <path
        d="M11.7759 0.229982C9.54273 0.228993 7.3595 0.890412 5.50221 2.13037C3.64492 3.37033 2.19709 5.13316 1.34181 7.19605C0.486533 9.25894 0.262219 11.5291 0.697282 13.7195C1.13234 15.9099 2.20717 17.922 3.7859 19.5015C5.36464 21.0809 7.37636 22.1567 9.56654 22.5928C11.7567 23.0288 14.027 22.8055 16.0902 21.9512C18.1535 21.0968 19.9171 19.6497 21.1579 17.793C22.3986 15.9362 23.0609 13.7532 23.0609 11.52C23.0604 8.52675 21.8715 5.65609 19.7554 3.53906C17.6393 1.42204 14.7692 0.231837 11.7759 0.229982ZM10.1299 18.4299L9.03993 17.3491L14.7699 11.5249L9.0309 5.68408L10.1309 4.61206L16.9249 11.5249L10.1299 18.4299Z"
        fill="currentColor"
      />
    </svg>
  );
}
