import React from 'react';
import { useRouter } from 'next/router';
import Modal from './Modal';

interface WarningModalProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  isOpen?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  selected?: string;
  message?: string;
  title?: string;
  router?: boolean;
}

export default function WarningModal({
  isOpen,
  setOpen,
  message,
  title,
  router: _router,
  ...rest
}: WarningModalProps) {
  const router = useRouter();
  return (
    <Modal
      isOpen={isOpen}
      className="w-full h-full flex justify-center items-center md:pl-44"
    >
      <div className="w-full lg:w-100 h-auto flex flex-col bg-primary-400 mx-1.5 rounded-md justify-start items-center text-white p-5">
        <h2 className="text-4xl mb-5 text-center">{title ?? '¡Cuidado!'}</h2>
        <p className="text-m text-center text-secondary-500">{message}</p>
        <div className=" w-full flex flex-row justify-center mt-10 mb-5">
          <button
            {...rest}
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
              if (_router) {
                router.push('/');
              }
            }}
            type="button"
            className="bebas mt-4 w-24 h-7 bg-primary-400 hover:border-secondary-400 hover:text-secondary-400 text-secondary-500 border-secondary-500 text-sm leading-normal rounded-full border-1"
          >
            Aceptar
          </button>
        </div>
      </div>
    </Modal>
  );
}
