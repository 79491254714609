import React from 'react';
import PlayIcon from '../icons/PlayIcon';
import Modal from '../modal/Modal';

const items = [
  {
    title: 'contact us',
    img: '/landing-page/call-center.png',
    text: (
      <p className="leading-tight">
        <span className="font-bold text-secondary-500">Envíanos</span> tu
        solicitud respondiendo el cuestionario de nuestra digital
      </p>
    ),
  },
  {
    title: 'receive a message',
    img: '/landing-page/phone-noti.png',
    text: (
      <p className="leading-tight">
        Recibe múltiples{' '}
        <span className="font-bold text-secondary-500">propuestas</span>
        por parte de nuestros aliados estratégicos.
      </p>
    ),
  },
  {
    title: 'options',
    img: '/landing-page/options.png',
    text: (
      <p className="leading-tight">
        Compara y elige entre las{' '}
        <span className="font-bold text-secondary-500">opciones</span> que más
        se ajusten a tus requerimientos.
      </p>
    ),
  },
  {
    title: 'change',
    img: '/landing-page/checklist.png',
    text: (
      <p className="leading-tight">
        <span className="font-bold text-secondary-500">
          Solicita los ajustes{' '}
        </span>{' '}
        que desees, cuantas veces sean necesarios.
      </p>
    ),
  },
  {
    title: 'celebrate',
    img: '/landing-page/celebration.png',
    text: (
      <p className="leading-tight">
        <span className="font-bold text-secondary-500 block">
          ¡Disfruta de tu evento{' '}
        </span>{' '}
        con toda la ayuda del equipo de Bloop!
      </p>
    ),
  },
];

export default function HowItWorks() {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <>
      <section className="w-full min-h-screen text-white mt-6 md:mt-16 lg:mt-26 flex flex-col justify-around">
        {/* Section title */}
        <div id="how-it-works" className="anchor w-full text-center">
          <h2 className="uppercase text-3xl md:text-4xl lg:text-5xl">
            ¿Cómo funciona?
          </h2>
        </div>
        {/* Content */}
        <div className="w-full flex md:flex-row flex-col justify-center items-center md:mt-16 mt-5">
          {items.map((item) => (
            <div
              key={item.img}
              className="flex flex-col justify-center items-center text-center md:w-1/5 w-full my-5 sm:my-0"
            >
              <div className="h-28 md:h-30 lg:h-32">
                <img
                  src={item.img}
                  alt={item.title}
                  className="mx-auto h-26 xl:h-28"
                />
              </div>
              <div className="mt-4 w-5/6 lg:w-52 xl:w-56 text-sm md:text-base">
                {item.text}
              </div>
            </div>
          ))}
        </div>
        <section className="py-18 lg:py-14">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setOpen(true);
            }}
            className="w-full flex items-center justify-center text-white"
          >
            <PlayIcon />
            <h2 className="text-xl md:text-2xl lg:text-2xl uppercase ml-6">
              Video <span className="text-secondary-500">tutorial</span>
            </h2>
          </button>
        </section>
      </section>

      <Modal
        className="bg-primary-400 rounded-md overflow-x-hidden table"
        isOpen={isOpen}
      >
        <div className="fixed -top-4 -right-4 text-white w-8 h-8 bg-white inline-flex rounded-full shadow z-50">
          <button
            type="button"
            className="m-auto"
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          >
            <svg
              className="w-6 h-6 text-primary-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <section className="px-2">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Rry0r-AX1I8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </section>
      </Modal>
    </>
  );
}
