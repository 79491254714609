import React from 'react';

export default function PlayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#289ED7"
      viewBox="0 0 24 24"
    >
      <path d="M12.0312 0.988037C9.78979 0.988037 7.59868 1.65266 5.73498 2.89795C3.87128 4.14324 2.41868 5.9133 1.56091 7.98413C0.703141 10.055 0.478725 12.3336 0.916012 14.532C1.3533 16.7304 2.43269 18.7498 4.01764 20.3347C5.60258 21.9197 7.62192 22.999 9.82031 23.4363C12.0187 23.8736 14.2974 23.649 16.3682 22.7913C18.4391 21.9335 20.209 20.4809 21.4543 18.6172C22.6996 16.7535 23.3643 14.5625 23.3643 12.321C23.3643 9.31535 22.1703 6.43272 20.0449 4.30737C17.9196 2.18202 15.0369 0.988037 12.0312 0.988037ZM8.64526 18.822V5.82202L17.0193 12.322L8.64526 18.822Z" />
    </svg>
  );
}
