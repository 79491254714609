import React from 'react';

interface FacebookIconProps {
  className?: string;
}

export default function FacebookIcon({ className = '' }: FacebookIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill="currentColor"
      viewBox="0 0 14 25"
    >
      <path d="M8.88596 24.3129V13.5731H12.473C12.653 12.1731 12.831 10.7987 13.013 9.38268H8.91296C8.91296 8.26268 8.90197 7.18292 8.92197 6.10192C8.93985 5.87787 9.00823 5.66053 9.12196 5.46667C9.26333 5.22833 9.4672 5.03349 9.7115 4.9027C9.9558 4.77192 10.2312 4.71046 10.508 4.72497C11.255 4.68797 12.008 4.69784 12.753 4.68884C12.875 4.68884 12.998 4.68884 13.124 4.68884V0.946649C11.7923 0.789526 10.4492 0.752848 9.11096 0.836786C8.08672 0.912012 7.11128 1.30422 6.31996 1.95886C5.77589 2.43 5.33901 3.0123 5.03871 3.66638C4.73841 4.32045 4.58164 5.03113 4.57896 5.75085C4.52496 6.85085 4.55196 7.95065 4.54396 9.05065C4.54396 9.15565 4.54396 9.2607 4.54396 9.4027H0.959961V13.5907H4.52696V24.3138" />
    </svg>
  );
}
