/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useRouter } from 'next/router';
import FacebookIcon from '../icons/FacebookIcon';
import InstagramIcon from '../icons/InstagramIcon';
import TwitterIcon from '../icons/TwitterIcon';
import LaptopImg from './img/LaptopImg';

export default function Home() {
  const router = useRouter();
  return (
    <section id="home" className="w-full text-white">
      <div className="w-full min-h-screen flex flex-col justify-around">
        {/* Home section content */}
        <div className="w-full flex flex-col md:flex-row justify-between pt-26 md:pt-20">
          <div className="w-full md:w-1/2 xl:w-106 flex flex-col justify-center pr-4">
            <h2 className="uppercase text-3xl md:text-4xl lg:text-5xl leading-none">
              Planifica tu evento{' '}
              <span className="block text-secondary-500">con Bloop</span>
            </h2>
            <p className="mt-10 leading-snug text-justify text-sm md:text-base">
              ¡Organizar eventos no tiene por qué ser un proceso complicado!
              Bloop te ofrece un servicio integral de event planning que
              simplificará la difícil tarea de planificar tu próxima
              celebración. Responde el cuestionario de nuestra solicitud
              digital, recibe propuestas por parte de nuestros aliados y
              prepárate para disfrutar con Bloop!
            </p>
            <button
              type="button"
              className="bg-secondary-500 rounded-full text-primary-500 py-1 mt-10 w-40 hover:bg-secondary-400"
              onClick={(e) => {
                e.preventDefault();
                router.push('/request');
              }}
            >
              <h6>Solicitud digital</h6>
            </button>
          </div>
          <div className="w-full md:w-1/2 h-full">
            <LaptopImg className="w-9/12 md:w-10/12 xl:w-106 my-14 md:my-0 mx-auto md:ml-auto" />
          </div>
        </div>
        {/* RRSS */}
        <div className="flex items-center">
          <a
            href="https://www.instagram.com/bloop_ve/"
            target="_blank"
            className="hover:text-secondary-500"
          >
            <InstagramIcon className="h-5 w-5 mx-2" />
          </a>
          <a
            href="https://www.facebook.com/bloop.ve"
            target="_blank"
            className="hover:text-secondary-500"
          >
            <FacebookIcon className="h-5 w-5 mx-2" />
          </a>
          <a
            href="https://twitter.com/bloop_ve"
            target="_blank"
            className="hover:text-secondary-500"
          >
            <TwitterIcon className="h-5 w-5 mx-2" />
          </a>
          <hr className="border border-secondary-500 w-48 ml-4" />
        </div>
      </div>
    </section>
  );
}
