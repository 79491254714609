import { ReactChildren } from 'react';
import { motion } from 'framer-motion';

interface ServiceItemProps {
  pos: number;
  idx: number;
  row: Array<{ icon: ReactChildren; title: string }>;
}

const createItem = (
  _pos: number,
  _idx: number,
  row: Array<{ icon: ReactChildren; title: string }>
) => ({
  styles: {
    transform: `translateX(${_pos * 0.005}rem)`,
  },
  item: row[_idx],
});

export default function ServiceItem({ pos, idx, row }: ServiceItemProps) {
  const item = createItem(pos, idx, row);
  const variants = {
    initial: { scale: 1 },
    animate: { scale: 1.1 },
    exit: { scale: 1 },
  };

  return (
    <motion.li
      className="flex flex-col items-center text-center mx-8 lg:mx-7 duration-500 transition-all"
      style={item.styles}
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {item?.item?.icon}
      <h2 className="uppercase text-xl text-white mt-3">{item?.item?.title}</h2>
    </motion.li>
  );
}
